import { useWorkflowUser } from "@/hooks";
import { buildYearMonth } from "@/lib/utils/date";
import { NextPage } from "next";
import { useRouter, usePathname } from "next/navigation";
import { useEffect } from "react";

const Index: NextPage = () => {
  const router = useRouter();
  const {
    user,
    isClerk,
    isManager,
    isAdmin,
    isStaff,
    isSiteManager,
    isAreaManager,
  } = useWorkflowUser();
  const pathname = usePathname();

  useEffect(() => {
    setTimeout(() => {
      if (!user?.id) {
        if (location.pathname !== "/login") router.push("/login");
        return;
      }
      const hasRoles = user?.roles;
      if (pathname === "/") {
        if (hasRoles) {
          if (isClerk || isManager || isAdmin || isAreaManager) {
            const yearMonth = buildYearMonth();
            router.push(`/manager/workrecord/${yearMonth}/all`);
          } else if (isSiteManager) {
            router.push("/staff");
          } else {
            router.push("/staff/workrecord/new");
          }
        }
      }
    }, 1);
  }, [
    pathname,
    router,
    user,
    isClerk,
    isManager,
    isAdmin,
    isStaff,
    isSiteManager,
    isAreaManager,
  ]);

  return <div></div>;
};
export default Index;
